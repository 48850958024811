/** @jsx jsx */
import Helmet from 'react-helmet'
import { Flex, jsx } from 'theme-ui'
import Layout from '../components/Layout'
import Button from '../components/Button'
import './styles.css'

function ReleasesPage() {
    return (
        <Layout>
            <Helmet>
                <title>版本记录 - FDA助手</title>
            </Helmet>
            <div
                sx={{
                    paddingY: 9,
                    paddingX: 5,
                }}
            >
                <h1
                    sx={{
                        margin: 0,
                        fontSize: 6,
                        fontWeight: 'normal',
                        textAlign: 'center',
                    }}
                >
                    发布记录
                </h1>
                <div
                    className="app"
                    sx={{
                        display: 'flex',
                        flexDirection: 'column-reverse'
                    }}
                >
                    <div className="item">
                        <h5>2021-03-25</h5>
                        <ol>
                            <li>首次发布上线。</li>
                            <li>功能包括：可以查看 FDA 注册常见问题/内容，自助进行 FDA 注册等。</li>
                            <li>内容有待逐步完善，没法一蹴而就。</li>
                        </ol>
                    </div>
                </div>
                <Button
                    as="a"
                    href="/"
                    sx={{ display: 'block', width: '50%', variant: 'buttons.outline', marginTop: 10, marginX: 'auto' }}
                >
                    返回首页
                </Button>
            </div>
        </Layout>
    )
}

export default ReleasesPage
